import React, {useCallback, useEffect, useRef, useState} from 'react';


import './App.css'

import {OpenAI} from 'openai';

// @ts-ignore
import Speech from 'speak-tts';


// import {useSpeechToText} from "./hooks/use-speech-to-text";

import soundOffIcon from './icons/iconmonstr-audio-10.svg'
import soundOnIcon from './icons/iconmonstr-sound-thin.svg'
import sendIcon from './icons/send-svgrepo-com.svg'
import microphoneIcon from './icons/microphone-svgrepo-com.svg'
// import {GptSetting} from "./components/gpt-setting/GptSetting";
// import {Icon} from "./Icon";
import {Chat} from "./page";
import {useSpeechToText} from "./hooks";


function App() {
    const speechMale = new Speech();

    const {start, stop, transcript} = useSpeechToText({continuous: true})

    speechMale.init({
        volume: 1,
        lang: 'en-GB',
        rate: 1,
        pitch: 1,
        splitSentences: true,
        voice: 'Google UK English Male'
    });

    const [isButtonActive, setIsButtonActive] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isAutoSend, setIsAutoSend] = useState(false);
    const [isAutoVoicing, setIsAutoVoicing] = useState(false);

    const [speechMessage, setSpeechMessage] = useState<string | null>(null);
    const [inputValue, setInputValue] = useState<string>('');
    const [gptSystemContent, setGptSystemContent] = useState<string>('');
    const [chat, setChat] = useState<any[]>([])


    const apiKey = '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'
    const openai = new OpenAI({
        apiKey: apiKey,
        dangerouslyAllowBrowser: true
    });

    const ref = useRef<HTMLDivElement | null>(null)


    const [endSpeechMessage, setEndSpeechMessage] = useState<string | null>(null)
    const onSpeak = (text: string) => {
        speechMale.speak({
            text: text,
            listeners: {
                onend: (event: any) => {
                    setEndSpeechMessage(event.target.text)
                }
            }
        })
    };

    useEffect(() => {
        if (endSpeechMessage && speechMessage?.endsWith(endSpeechMessage.split(' ').pop() ?? '')) {
            setEndSpeechMessage(null)
            setSpeechMessage(null)
        }
    }, [speechMessage, endSpeechMessage]);


    const sendMessage = async () => {
        if (inputValue.length > 0) {
            try {
                const messages = {
                    "role": "user",
                    "content": inputValue
                }
                setChat((prevState) => ([...prevState, messages]))
                setIsLoading(true)
                const response = await openai.chat.completions.create({
                    model: "gpt-4",
                    messages: [
                        ...(gptSystemContent
                                ? [{
                                    role: "system",
                                    content: gptSystemContent
                                }]
                                : []
                        ),
                        ...chat,
                        {
                            role: 'user',
                            content: messages.content
                        }
                    ],
                    temperature: 1,
                    max_tokens: 1024,
                    top_p: 1,
                    frequency_penalty: 0,
                    presence_penalty: 0,
                });

                const content = response.choices[0].message.content

                setInputValue('')
                setChat((prevState) => ([...prevState, {role: 'assistant', content: content}]))
                setIsLoading(false)

                if (isAutoVoicing) {
                    setSpeechMessage(content)
                    onSpeak(content ?? '')
                }


            } catch (error) {
                console.error('Error sending message:', error);
            }
        }
    };

    const handleMouseDown = async () => {
        setIsButtonActive(true);

        start()

        speechMale.cancel();
    };

    const handleMouseUp = async () => {
        setIsButtonActive(false);
        stop()

        if (isAutoSend) {
            await sendMessage()
        }
    };


    const _handleKeyDown = (event: any) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault();
            sendMessage().then();
        }
    };
    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => _handleKeyDown(event)

    useEffect(() => {
        if (transcript) {
            setInputValue(transcript)
        }
    }, [transcript])

    useEffect(() => {
        if (ref.current) {
            ref.current.scrollIntoView({behavior: 'smooth', block: 'center'});
        }
    }, [chat]);

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => _handleKeyDown(event)

        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    return (
        <div id='App' className='App'>
            <Chat/>
            {/*<div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '0 20px'}}>*/}
            {/*    <h1>Chat</h1>*/}
            {/*    <GptSetting setGptSystemContent={setGptSystemContent} gptSystemContent={gptSystemContent}/>*/}
            {/*</div>*/}


            {/*<div className='chatContainer'>*/}
            {/*    {chat.map((value, index) => (*/}
            {/*        <div*/}
            {/*            key={index}*/}
            {/*            style={{*/}
            {/*                width: '95%',*/}
            {/*                display: 'flex',*/}
            {/*                alignItems: value.role === 'user' ? 'flex-end' : 'flex-start',*/}
            {/*                flexDirection: 'column'*/}
            {/*            }}*/}
            {/*        >*/}
            {/*            <div ref={ref}>*/}
            {/*                {*/}
            {/*                    speechMessage === value.content ? (*/}
            {/*                            <Icon icon={soundOffIcon}*/}
            {/*                                  onClick={() => {*/}
            {/*                                      setSpeechMessage(null)*/}
            {/*                                      speechMale.cancel();*/}
            {/*                                  }}*/}
            {/*                            />*/}
            {/*                        ) :*/}
            {/*                        (*/}
            {/*                            <Icon icon={soundOnIcon}*/}
            {/*                                  onClick={() => {*/}
            {/*                                      setSpeechMessage(value.content)*/}
            {/*                                      onSpeak(value.content)*/}
            {/*                                  }}*/}
            {/*                            />*/}
            {/*                        )*/}
            {/*                }*/}
            {/*            </div>*/}
            {/*            <div*/}
            {/*                style={{*/}
            {/*                    maxWidth: '70%',*/}
            {/*                    width: 'fit-content',*/}
            {/*                    backgroundColor: value.role === 'user' ? 'lightblue' : 'lightgreen',*/}
            {/*                    borderRadius: '8px',*/}
            {/*                    padding: '8px',*/}
            {/*                    textAlign: value.role === 'user' ? 'right' : 'left'*/}

            {/*                }}*/}
            {/*            >*/}
            {/*                <p>{value.content}</p>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    ))}*/}
            {/*    {*/}
            {/*        isLoading && <div style={{marginLeft: "20px"}}>...</div>*/}
            {/*    }*/}
            {/*</div>*/}
            {/*<div className='controlContainer'>*/}
            {/*    <div className='controlInfo'>*/}
            {/*        <button*/}
            {/*            className={isButtonActive ? 'inactive active ' : 'inactive'}*/}
            {/*            onMouseDown={handleMouseDown}*/}
            {/*            onTouchStart={handleMouseDown}*/}
            {/*            onMouseUp={handleMouseUp}*/}
            {/*            onTouchEnd={handleMouseUp}*/}

            {/*            disabled={isLoading}*/}
            {/*        >*/}
            {/*            <Icon icon={microphoneIcon}/>*/}
            {/*        </button>*/}
            {/*        <input*/}
            {/*            value={inputValue}*/}
            {/*            onChange={(e) => setInputValue(e.target.value)}*/}
            {/*            readOnly={isButtonActive || isLoading}*/}
            {/*            style={{*/}
            {/*                width: '100%',*/}
            {/*                height: "40px",*/}
            {/*                resize: 'none'*/}
            {/*            }}*/}
            {/*            onKeyDown={handleKeyDown}*/}
            {/*        />*/}
            {/*        <button*/}
            {/*            className='inactive' onClick={sendMessage}*/}
            {/*            disabled={isLoading}*/}
            {/*        >*/}
            {/*            <Icon icon={sendIcon}/>*/}
            {/*        </button>*/}
            {/*    </div>*/}

            {/*    <div className='controlCheckbox'>*/}
            {/*        <div style={{display: 'flex', gap: '10px'}}>*/}
            {/*            <input type='checkbox' checked={isAutoSend} onClick={() => setIsAutoSend(!isAutoSend)}/>*/}
            {/*            Auto Send message*/}
            {/*        </div>*/}
            {/*        <div style={{display: 'flex', gap: '10px'}}>*/}
            {/*            <input type='checkbox' checked={isAutoVoicing}*/}
            {/*                   onClick={() => setIsAutoVoicing(!isAutoVoicing)}/>*/}
            {/*            Auto voicing message*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}


        </div>
    );
}

export default App;
