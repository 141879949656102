export const Icons = {
    dots: require('./dots.svg').default,
    send: require('./send.svg').default,
    microphone: require('./microphone.svg').default,
    voicingUnlock: require('./voicing-unlock.svg').default,
    voicingLock: require('./voicing-lock.svg').default,
    sendingLock: require('./sending-lock.svg').default,
    sendingUnlock: require('./sending-unlock.svg').default,
    soundLock: require('./sound-lock.svg').default,
    soundUnLock: require('./sound-unock.svg').default,
    logo: require('./logo.svg').default,
};
