import React, {ChangeEvent, useEffect, useState} from 'react'

import * as Styled from './input.styled'

import {useSpeechToText, useTextToSpeech} from "../../hooks";

import {Icon} from "../icon/icon";
import {Icons} from "../../icons";

interface IInput {
    onSendMessage: (message: string) => void
    isLoading: boolean
    isAutoSend: boolean
}

export const Input = ({isLoading, onSendMessage, isAutoSend}: IInput) => {
    const [inputValue, setInputValue] = useState<string>('');

    const {start, stop, transcript, isListening} = useSpeechToText({continuous: true})
    const {speechMale} = useTextToSpeech()

    const onSend = () => {
        if (inputValue.length) {
            onSendMessage(inputValue)
            setInputValue('')
        }
    };

    const handleMouseDown = async () => {
        start()

        speechMale.cancel();
    };

    const handleMouseUp = async () => {
        stop()

        if (isAutoSend) {
            onSend()
        }
    };


    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        setInputValue(e.target.value)
    };


    useEffect(() => {
        if (transcript) {
            setInputValue(transcript)
        }
    }, [transcript])

    const _handleKeyDown = (event: any) => {
        if (event.key === 'Enter' && !event.shiftKey && inputValue.length) {
            event.preventDefault();
            onSend()
        }
    };


    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => _handleKeyDown(event)

        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    return (
        <Styled.Container>
            <div className='input'>
                <Styled.Input
                    placeholder='Write'
                    value={inputValue}
                    onChange={onChange}
                />
                <button className='send' disabled={isListening || isLoading || !inputValue.length} onClick={onSend}>
                    <Icon icon={Icons.send} height='1.5rem' cursor='pointer' background='#fff'/>
                </button>
            </div>
            <Styled.Microphone
                onMouseDown={handleMouseDown}
                onTouchStart={handleMouseDown}
                onMouseUp={handleMouseUp}
                onTouchEnd={handleMouseUp}
                isActive={isListening}
            >
                <Icon icon={Icons.microphone} height='1.5rem' cursor='pointer' background='#fff'/>
            </Styled.Microphone>
        </Styled.Container>
    )
}
