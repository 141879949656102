import React, {ReactNode, useState} from 'react'
import Modal from "react-modal";

import * as Styled from './gpt-setting.styled'


const customStyles = {
    overlay: {zIndex: 5, backgroundColor: 'rgba(6, 1, 7, 0.40)', backdropFilter: 'blur(4px)'},
    content: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0 20px',
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: 'rgba(6, 1, 7, 0.40)',
        border: 'none'
    }
};
export const GptSetting = ({setGptSystemContent, gptSystemContent, children}: {
    setGptSystemContent: any,
    gptSystemContent: string
    children: ReactNode;
}) => {
    const [isAddTemplateModalOpen, setIsAddTemplateModalOpen] = useState<boolean>(false);
    const [_gptSystemContent, _setGptSystemContent] = useState<string>(gptSystemContent ?? '');


    if (isAddTemplateModalOpen) {
        Modal.setAppElement('#App');
    }

    const onClose = () => {
        setIsAddTemplateModalOpen(false)
    };

    const onSave = () => {
        setGptSystemContent(_gptSystemContent)
        onClose()
    };

    const onCancel = () => {
        _setGptSystemContent(gptSystemContent ?? '')
        onClose()
    };

    return (
        <div>
            <div onClick={() => setIsAddTemplateModalOpen(true)}>{children}</div>
            <Modal
                isOpen={isAddTemplateModalOpen}
                onRequestClose={onClose}
                style={customStyles}
                contentLabel='Example Modal'
            >
                <Styled.Content>
                    <p>Setting Gpt System Content </p>

                    <Styled.Textarea
                        value={_gptSystemContent}
                        onChange={(e) => _setGptSystemContent(e.target.value)}
                    />

                    <div style={{width: '100%', display: 'flex', justifyContent: 'space-between', }}>
                        <Styled.Button className='cancel' onClick={onCancel}>cancel</Styled.Button>
                        <Styled.Button onClick={onSave}>save</Styled.Button>
                    </div>
                </Styled.Content>
            </Modal>
        </div>
    )
}
