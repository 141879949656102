import styled from "styled-components";
import {COLORS} from "../../theme";

export const Content = styled.div`
    width: clamp(300px,100%, 600px);
    background: ${COLORS.mainBackground};
    padding: 20px;
    display: flex;
    align-items: center;
    flex-direction: column;

    & > p {
        color: ${COLORS.white};
    }
`

export const Textarea = styled.textarea`
    margin: 20px 0;
    width: 100%;
    height: 600px;
    min-height: 100px;
    max-height: 300px;
    resize: vertical;

    padding: 12px 16px;
    border-radius: 10px;

    outline: none;
    color: ${COLORS.white};

    background: transparent;

    &::placeholder {
        color: rgba(255, 255, 255, 0.6);
    }
`

export const Button = styled.button`
    padding: 8px 12px;
    border-radius: 10px;
    border: none;
    cursor: pointer;

    color: ${COLORS.white};;
    background: #00AC83;


    &.cancel {
        background: red;
    }
`
