import styled, {css} from "styled-components";
import {COLORS, MEDIA} from "../../theme";

const Center = css`
    display: flex;
    align-items: center;
    justify-content: center;
`

export const Container = styled.div`
    width: 100%;
    height: 40px;


    display: flex;
    gap: 1rem;

    & > .input {
        width: 100%;

        position: relative;

        & > .send {
            position: absolute;
            top: 0;
            right: 0;
            height: 100%;
            aspect-ratio: 1/1;

            border: none;
            border-radius: 10px;

            background: ${COLORS.secondBackground};
            ${Center};

            cursor: pointer;

            &:disabled {
                opacity: 0.4;
            }
        }
    }


    @media screen and (min-width: ${MEDIA.laptop_custom}) {
        max-width: 900px;
        margin: 0 auto;
    }

    
`

export  const Microphone = styled.button<{isActive:boolean}>`
    height: 100%;
    aspect-ratio: 1/1;

    border-radius: 10px;
    border: none;

    background: #00AC83;
    ${Center};
    cursor: pointer;

    ${({isActive})=> isActive && css`
        background: red;
    `}}
`

export const Input = styled.input`
    width: 100%;
    height: 100%;
    border-radius: 10px;

    background: #00000040;

    padding: 12px 45px 12px 15px;
    border: none;
    outline: none;
    
    color: ${COLORS.white};


    &::placeholder {
        color: rgba(255, 255, 255, 0.6);
    }

`
