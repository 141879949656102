// @ts-ignore
import Speech from 'speak-tts';
import {useEffect, useState} from "react";

export const useTextToSpeech = () => {
    const speechMale = new Speech();

    speechMale.init({
        volume: 1,
        lang: 'en-GB',
        rate: 1,
        pitch: 1,
        splitSentences: true,
        voice: 'Google UK English Male'
    });

    const [speechMessage, setSpeechMessage] = useState<string | null>(null);
    const [endSpeechMessage, setEndSpeechMessage] = useState<string | null>(null)

    const onSpeak = (text: string) => {
        setSpeechMessage(text)

        speechMale.speak({
            text: text,
            listeners: {
                onend: (event: any) => {
                    setEndSpeechMessage(event.target.text)
                }
            }
        })
    };

    const onStop = () => {
        setSpeechMessage(null)
        speechMale.cancel();
    };


    useEffect(() => {
        if (endSpeechMessage && speechMessage?.endsWith(endSpeechMessage.split(' ').pop() ?? '')) {
            setEndSpeechMessage(null)
            setSpeechMessage(null)
        }
    }, [speechMessage, endSpeechMessage]);


    return {speechMale, onSpeak, onStop, speechMessage}

}
