import {useEffect, useRef, useState} from "react";

interface IOptions {
    continuous?: boolean
    interimResults?: boolean
    lang?: 'en-US' | 'uk-UA'
}

export const useSpeechToText = (options: IOptions) => {
    const [isListening, setIslistening] = useState(false)
    const [transcript, setTranscript] = useState("")
    const recgonitionRef = useRef<any | null>(null)

    useEffect(() => {
        if (!('webkitSpeechRecognition' in window)) {
            console.error("Web speech api is not supported.")
            return
        }

        recgonitionRef.current = new window.webkitSpeechRecognition()

        const recognition = recgonitionRef.current

        recognition.interimResults = options.interimResults ?? true
        recognition.lang = options.lang || "en-US"
        recognition.continuous = options.continuous || false

        if ("webkitSpeechGrammarList" in window) {
            const grammar = "#JSGF V1.0; grammar punctuation; public <punc> = • 1 , I ? | !] ; I : ;"
            const speechRecognitionlist = new window.webkitSpeechGrammarList()
            speechRecognitionlist.addFromString(grammar, 1)
            recognition.grammars = speechRecognitionlist
        }

        recognition.onresult = (event: any) => {
            let text = ""
            for (let i = 0; i < event.results.length; i++) {
                text += event.results[i][0].transcript
                // console.log(event.results[i][0].transcript, '+===========')
            }
            setTranscript(text)
        }

        recognition.onerror = () => {
            console.log('errorr +++++++++++')
        }

        recognition.onend = () => {
            setIslistening(false)
            setTranscript('')
        }

        return () => {
            recognition.stop()
        }

    }, [])

    useEffect(() => {
        if (isListening) {

        }

    }, [isListening, transcript]);

    const start = () => {
        if (recgonitionRef.current && !isListening) {
            recgonitionRef.current.start()
            setIslistening(true)
        }
    }

    const stop = () => {
        if (recgonitionRef.current && isListening) {
            recgonitionRef.current.stop()
            setIslistening(false)
        }
    }

    return {
        start, stop, isListening, transcript
    }
}
