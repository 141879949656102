import styled, {css} from 'styled-components';
import {Icon} from "../../components";
import {FONTS, COLORS, MEDIA} from "../../theme";


export const Container = styled.div`
    width: 100%;
    height: 100%;
    max-height: 100%;


    display: flex;
    flex-direction: column;

    margin: 0 auto;
    padding: 51px 35px;

    background: ${COLORS.mainBackground};

    font-family: ${FONTS.FAMILIES.roboto};
`;

export const Loading = styled.div`
        width: fit-content;
        background: ${COLORS.secondBackground};
        color: ${COLORS.white};

        padding: 10px 14px;
        border-radius: 20px;
`

export const Header = styled.header`
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > h1 {
        color: ${COLORS.white};
        font-size: ${FONTS.SIZES.xxxxl};
        font-weight: ${FONTS.WEIGHTS.bold};
        line-height: ${FONTS.SIZES.xxxxxxl_};
    }

    & > img {
        width: 100px;
        height: 100px;
    }


    & > div {
        display: flex;
        gap: 20px;
    }
`

export const Content = styled.div`
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    gap: 12px;
`

export const Chat = styled.div`
    width: 100%;
    height: calc(100% - 40px);

    display: flex;
    flex-direction: column;
    gap: 20px;
    overflow-y: auto;
    padding-top: 20px;

    @media screen and (min-width: ${MEDIA.laptop_custom}) {
        width: 900px;
        margin: 0 auto;
    }
`

export const HoverInfo = styled.div`
    position: relative;

    &:hover {
        &:after {
            content: attr(data-text);
            position: absolute;
            z-index: 5;
            display: inline-block;

            width: fit-content;

            top: calc(100% + 5px);
            left: 50%;
            transform: translateX(-50%);
            padding: 5px 10px;


            background: rgba(0, 0, 0, 0.8);
            color: ${COLORS.white};
            border-radius: 5px;
        }
    }


`

export const HeaderIcon = styled(Icon)`
    cursor: pointer;
    height: 1.5rem;

    background: ${COLORS.white};

    position: relative;
    z-index: 1;

    &.dot {
        &:hover {
            background: ${COLORS.secondBackground} !important;
        }
    }
`

export const MessageContainer = styled.div<{ isUser: boolean }>`
    width: 100%;
    display: flex;
    align-items: ${({isUser}) => isUser ? 'flex-end' : 'flex-start'};
    flex-direction: column;


`

export const Message = styled.div<{ isUser: boolean }>`
    max-width: 70%;
    width: fit-content;
    background: ${({isUser}) => isUser ? '#272A35' : COLORS.secondBackground};


    border-radius: 20px;
    padding: 10px 14px;
    text-align: ${({isUser}) => isUser ? 'right' : 'left'};

    & > p {
        color: ${COLORS.white} !important;
        font-size: ${FONTS.SIZES.m};
        font-weight: ${FONTS.WEIGHTS.light};
        line-height: ${FONTS.SIZES.l};
    }


    position: relative;

    & > .icon {
        cursor: pointer;

        position: absolute;
        height: 20px;
        aspect-ratio: 1/1;
        display: flex;
        align-items: center;
        justify-content: center;

        background: ${COLORS.white};

        border-radius: 50%;

        bottom: 2px;
        ${({isUser}) => isUser ? css`left: -10px;` : css`right: -10px;`};
    }
`
