import React, {useEffect, useRef, useState} from 'react'
import {OpenAI} from 'openai';

import * as Styled from './chat.styled'
import {GptSetting, Input, Icon} from "../../components";
import {FilePath, useTextToSpeech} from "../../hooks";

import {Icons} from "../../icons";


export const Chat = () => {
    const {onSpeak, speechMessage, onStop} = useTextToSpeech()

    const [isLoading, setIsLoading] = useState(false);

    const [isAutoSend, setIsAutoSend] = useState(false);
    const [isAutoVoicing, setIsAutoVoicing] = useState(false);


    const [gptSystemContent, setGptSystemContent] = useState<string>('');
    const [chat, setChat] = useState<any[]>([])


    const apiKey = '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'
    const openai = new OpenAI({
        apiKey: apiKey,
        dangerouslyAllowBrowser: true
    });


    const onSendMessage = async (message: string) => {

        try {
            const messages = {
                "role": "user",
                "content": message
            }
            setChat((prevState) => ([...prevState, messages]))
            setIsLoading(true)
            const response = await openai.chat.completions.create({
                model: "gpt-4",
                messages: [
                    ...(gptSystemContent
                            ? [{
                                role: "system",
                                content: gptSystemContent
                            }]
                            : []
                    ),
                    ...chat,
                    {
                        role: 'user',
                        content: messages.content
                    }
                ],
                temperature: 1,
                max_tokens: 1024,
                top_p: 1,
                frequency_penalty: 0,
                presence_penalty: 0,
            });

            const content = response.choices[0].message.content

            setChat((prevState) => ([...prevState, {role: 'assistant', content: content}]))
            setIsLoading(false)

            if (isAutoVoicing) {
                onSpeak(content ?? '')
            }


        } catch (error) {
            console.error('Error sending message:', error);
        }

    };


    const ref = useRef<HTMLDivElement | null>(null)

    useEffect(() => {
        if (ref.current) {
            ref.current.scrollIntoView({behavior: 'smooth', block: 'center'});
        }
    }, [chat]);


    return (
        <Styled.Container>
            <Styled.Header>
                <img src={FilePath(Icons.logo)}/>
                <div>

                    <Styled.HoverInfo
                        data-text={isAutoSend ? 'Automatic message sending is unlocked' : 'Automatic sending of messages is blocked'}
                    >
                        <Styled.HeaderIcon
                            icon={isAutoSend ? Icons.sendingUnlock : Icons.sendingLock}
                            onClick={() => setIsAutoSend(!isAutoSend)}

                        />
                    </Styled.HoverInfo>

                    <Styled.HoverInfo
                        data-text={isAutoVoicing ? 'Automatic announcement of messages is unlocked' : 'Automatic announcement of messages is blocked'}
                    >
                        <Styled.HeaderIcon
                            icon={isAutoVoicing ? Icons.voicingUnlock : Icons.voicingLock}
                            onClick={() => setIsAutoVoicing(!isAutoVoicing)}
                        />
                    </Styled.HoverInfo>

                    <GptSetting setGptSystemContent={setGptSystemContent} gptSystemContent={gptSystemContent}>
                        <Styled.HeaderIcon className='dot' icon={Icons.dots}/>
                    </GptSetting>


                </div>
            </Styled.Header>
            <Styled.Content>
                <Styled.Chat>
                    {chat.map((value, index) => {


                            return (
                                <Styled.MessageContainer
                                    key={index}
                                    isUser={value.role === 'user'}
                                    ref={ref}
                                >
                                    <Styled.Message
                                        isUser={value.role === 'user'}
                                    >
                                        <p>{value.content}</p>

                                        <div className='icon'
                                             onClick={speechMessage === value.content ? onStop : onSpeak.bind(this, value.content)}
                                        >
                                            <Icon
                                                icon={speechMessage === value.content ? Icons.soundLock : Icons.soundUnLock}
                                                height='12px'
                                            />
                                        </div>
                                    </Styled.Message>
                                </Styled.MessageContainer>
                            )
                        }
                    )}
                    {
                        isLoading && <Styled.Loading style={{marginLeft: "20px"}}>...</Styled.Loading>
                    }
                </Styled.Chat>
                <Input isLoading={isLoading} onSendMessage={onSendMessage} isAutoSend={isAutoSend}/>
            </Styled.Content>
        </Styled.Container>
    )
}
