import styled from "styled-components";
import {FilePath} from "../../hooks";


export interface IIcon {
    icon: string;
    height?: string;
    background?: string;
    cursor?: 'pointer' | 'text';
}

export const Icon = styled.div<IIcon>`
    height: ${({height}) => height ?? '1.25rem'};

    aspect-ratio: 1/1;
    background: ${({background}) => background ?? '#000'};

    -webkit-mask-image: url(${({icon}) => FilePath(icon)});
    -webkit-mask-size: 100% 100%;
    mask-image: url(${({icon}) => FilePath(icon)});
    cursor: ${({cursor}) => cursor ?? 'text'};
`;
